import React from 'react'
import { graphql } from 'gatsby'
import { Block, Cta, Layout, Hero } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '../pageBuilder/wrapper'

const IndexPage = ({ data, pageContext }) => {
  const {
    moduleArea,
    seoMetaTags,
    heroImage,
    heroHeading,
    heroImageMobile,
    introContent,
    introHeading,
    introButton
  } = data.home

  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Hero
        heading={heroHeading}
        image={heroImage}
        mobileImage={heroImageMobile}
      />
      <Cta heading={introHeading} content={introContent} link={introButton} />
      <BlockWrapper locale={pageContext.locale} blocks={moduleArea} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      id
      title
      introContent
      introHeading
      introButton {
        ...Link
      }
      heroHeading
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      heroImageMobile: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "crop", w: "750", q: 60 }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsContentCard {
          ...ContentCard
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsLatestNews {
          ...LatestNews
        }
        ... on DatoCmsVideo {
          ...Video
        }
      }
    }
    allSitePage {
      nodes {
        ...MenuHelpersAllPages
      }
    }
  }
`
